<script setup lang="ts">
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import airpazFeatures from '~/config/airpazFeatures';
import { computed, useRuntimeConfig } from '#imports';

import 'swiper/css';
import 'swiper/css/pagination';

interface IProps {
  type?: string[];
}

const props = defineProps<IProps>();

const airpazFeaturesList = computed(() => airpazFeatures(...(props.type ?? 'all')));

const modules = [Autoplay, Pagination];
</script>

<template>
  <div class="w-full !min-h-[250px]">
    <Swiper
      loop
      :pagination="{ el: '.features-swiper-pagination', clickable: true }"
      :autoplay="{
        delay: 2500,
        disableOnInteraction: true,
      }"
      :slides-per-view="2"
      centered-slides
      watch-slides-progress
      grab-cursor
      :modules="modules"
      class="swiper h-full w-full"
    >
      <SwiperSlide
        v-for="(feature, index) in airpazFeaturesList"
        :key="index"
        class="flex justify-center"
      >
        <div
          class="flex flex-col items-center opacity-30 scale-90 transition-all duration-500 w-full h-full"
          data-testid="whyAirpaz-information-container"
        >
          <NuxtImg
            :src="`${useRuntimeConfig().public.cdnImageUrl}/illustrations/${feature.icon}.svg`"
            height="100"
            width="100"
            format="webp"
            class="w-[100px] h-[100px]"
            loading="lazy"
            :alt="$t(feature.title)"
          />

          <h3 class="feature-title text-base font-semibold leading-normal text-center my-10">
            {{ $t(feature.title) }}
          </h3>

          <p class="feature-description text-small text-gray-dark text-center leading-normal">
            {{ $t(feature.description) }}
          </p>
        </div>
      </SwiperSlide>

      <div class="features-swiper-pagination absolute flex gap-x-5 w-full justify-center items-center bottom-10" />
    </Swiper>
  </div>
</template>

<style scoped lang="scss">
.swiper-slide {
  &-active div {
    @apply opacity-100 scale-100;

    &-title,
    &-description {
      @apply opacity-100;
    }
  }

  &-visible div {
    @apply h-full;
  }

  &-next div,
  &-prev div {
    @apply pt-50;

    transform: scale(1.4);

    .feature-title,
    .feature-description {
      display: none;
    }
  }
}

.features-swiper-pagination {
  :deep(.swiper-pagination-bullet) {
    @apply w-[5px] h-[5px] bg-gray-dark m-0;

    &.swiper-pagination-bullet-active {
      @apply scale-150 bg-opacity-100;
    }
  }
}
</style>
